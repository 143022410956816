import React, { useState, useEffect } from "react";
import UserNav from "../user-navbar/user-navbar";
import { FiSun } from "react-icons/fi";
import moment from "moment";
import { HiUsers } from "react-icons/hi";
import AttendanceOverview from "./attendance_table";
import LeaveRequestUser from "./leave_request_user";

const Dashboard = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [leaveCounts, setLeaveCounts] = useState([]);
  const [employeeNo, setEmployeeNo] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (employeeNo) {
      // Fetch leave data with employee_no
      fetch(
        `https://back-dlh-hris.quortech-technologies.com/v1/hris/leave/GetLeaveCountsdaypart?employee_no=${employeeNo}`
      )
        .then((response) => response.json())
        .then((data) => {
          // Filter and map leave types with valid counts
          const validLeaveCounts = data.leaves
            .filter((leave) => parseFloat(leave.leave_count) > 0) // Only include leaves with valid counts
            .map((leave) => ({
              type: leave.category_name,
              current: parseFloat(leave.leave_count) || 0,
              actual: parseFloat(leave.actualLeaveCounts) || 0,
            }));
          setLeaveCounts(validLeaveCounts);
        })
        .catch((error) => {
          console.error("Error fetching leave data:", error);
        });
    }
  }, [employeeNo]);

  useEffect(() => {
    // Retrieve employee_no from cookie
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const employeeNoFromCookie = getCookie("employee_no");
    if (employeeNoFromCookie) {
      setEmployeeNo(employeeNoFromCookie);
    }
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-[#F1F2F6] overflow-y-auto h-screen">
      <div>
        <UserNav />
      </div>

      <div className="flex gap-10 mt-5 mx-[5%] justify-center">
        <div>
          <div className="bg-white p-2 py-[10%] rounded-lg h-[100%]">
            <div className="flex items-center gap-5 px-10">
              <div>
                <FiSun className="w-[80px] h-[80px] opacity-45" />
              </div>
              <div className="font-bold text-[28px] text-[#001F3F]">
                {currentTime}
                <p className="text-lg font-normal">Real Time Insight</p>
              </div>
            </div>

            <div className="mt-[50px] font-bold px-10">
              <p className="font-bold text-[#001F3F] text-[25px]">Today :</p>
              <div className="text-[#001F3F] text-[20px]">{currentDate}</div>
            </div>

            {/* Leave Request Button */}
            <div className="mt-5 flex justify-center">
              <button
                onClick={handleOpenModal}
                className="bg-[#001F3F] text-white py-3 px-6 rounded-lg text-lg font-semibold shadow-md hover:bg-[#582379] transition"
              >
                Request Leave
              </button>
            </div>
          </div>
        </div>

        {/* Leave count cards */}
        <div className="flex flex-col gap-8 w-full">
          {/* First row: First 3 cards */}
          <div className="grid grid-cols-3 gap-8">
            {leaveCounts.slice(0, 3).map((leave) => (
              <div
                key={leave.type}
                className="bg-white p-5 rounded-lg w-[320px] h-[250px]"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-[48px] text-[#001F3F]">
                      {leave.current.toFixed(1)}/{leave.actual.toFixed(1)}
                    </p>
                  </div>
                  <div className="bg-[#E6EAF5] rounded-full p-3">
                    <HiUsers className="h-[40px] w-[40px]" />
                  </div>
                </div>
                <div>
                  <p className="text-[25px] capitalize">{leave.type} Leaves</p>
                  <div className="flex items-center gap-3 mt-5">
                    <div className="bg-[#FA0BA9] rounded-full p-2"></div>
                    <p className="text-lg">
                      Remaining Count: {leave.current.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Second row */}
          {leaveCounts.length > 3 && (
            <div
              className={`${
                leaveCounts.length > 6
                  ? "flex gap-4 overflow-x-auto"
                  : "grid grid-cols-3 gap-8"
              }`}
            >
              {leaveCounts.slice(3).map((leave) => (
                <div
                  key={leave.type}
                  className={`bg-white p-5 rounded-lg ${
                    leaveCounts.length > 6
                      ? "flex-shrink-0 w-[200px] h-[120px]"
                      : "w-[320px] h-[250px]"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <p
                        className={`${
                          leaveCounts.length > 6 ? "text-[20px]" : "text-[48px]"
                        } text-[#001F3F]`}
                      >
                        {leave.current.toFixed(1)}/{leave.actual.toFixed(1)}
                      </p>
                    </div>
                    <div className="bg-[#E6EAF5] rounded-full p-3">
                      <HiUsers
                        className={`${
                          leaveCounts.length > 6
                            ? "h-[20px] w-[18px]"
                            : "h-[40px] w-[40px]"
                        }`}
                      />
                    </div>
                  </div>
                  <div>
                    <p
                      className={`capitalize ${
                        leaveCounts.length > 6 ? "text-[15px]" : "text-[25px]"
                      }`}
                    >
                      {leave.type} Leaves
                    </p>
                    <div className="flex items-center gap-3 mt-5">
                      <div
                        className={`bg-[#FA0BA9] rounded-full ${
                          leaveCounts.length > 6 ? "p-1" : "p-2"
                        }`}
                      ></div>
                      <p
                        className={`${
                          leaveCounts.length > 6 ? "text-sm" : "text-lg"
                        }`}
                      >
                        Remaining Count: {leave.current.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Attendance Overview */}
      <div className="mt-[60px] mx-[5%]">
        <AttendanceOverview />
      </div>

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-400 bg-opacity-50">
          <LeaveRequestUser
            onClose={handleCloseModal}
            leaveCounts={leaveCounts}
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
