import React, { useState, useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import Calendar from "react-calendar";
import moment from "moment";
import "react-calendar/dist/Calendar.css";

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

const LeaveRequestUser = ({ onClose, employeeNo }) => {
  const [restrictedDates, setRestrictedDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [reason, setReason] = useState("");
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState(""); // "success" or "error"
  const [showPopup, setShowPopup] = useState(false);

  const token = getCookie("auth_token");

  useEffect(() => {
    const fetchRestrictedDates = async () => {
      try {
        const response = await fetch(
          "https://back-dlh-hris.quortech-technologies.com/v1/hris/leave/getRestrictedDate",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setRestrictedDates(data || []);
      } catch (error) {
        console.error("Error fetching restricted dates:", error);
      }
    };

    const fetchLeaveTypes = async () => {
      try {
        const empNo = employeeNo || getCookie("employee_no");
        const response = await fetch(
          `https://back-dlh-hris.quortech-technologies.com/v1/hris/leave/GetLeaveCountsdaypart?employee_no=${empNo}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch leave types.");
        }

        const data = await response.json();
        if (data.leaves) {
          const formattedLeaveTypes = data.leaves.map((leave) => ({
            category_id: leave.leave_category_id,
            category_name: leave.category_name,
            leave_count: leave.leave_count,
          }));
          setLeaveTypes(formattedLeaveTypes);
        } else {
          console.error(
            "Error fetching leave types:",
            data.message || "Unknown error."
          );
        }
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    };

    fetchRestrictedDates();
    fetchLeaveTypes();
  }, [employeeNo, token]);

  const handleDateClick = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const restricted = restrictedDates.find(
      (d) => d.restricted_dates === formattedDate
    );

    if (restricted) {
      setPopupMessage(
        `The selected date (${formattedDate}) is restricted due to: ${restricted.reason_for_restriction}`
      );
      setPopupType("error");
      setShowPopup(true);
    } else if (!selectedDates.includes(formattedDate)) {
      setSelectedDates([...selectedDates, formattedDate]);
      setLeaveDetails([
        ...leaveDetails,
        { date: formattedDate, is_short_leave: false, leave_category: "" },
      ]);
    }
  };

  const handleRemoveDate = (index) => {
    const newDates = [...selectedDates];
    const newDetails = [...leaveDetails];
    newDates.splice(index, 1);
    newDetails.splice(index, 1);
    setSelectedDates(newDates);
    setLeaveDetails(newDetails);
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        employee_no: employeeNo || getCookie("employee_no"),
        reason: reason.trim(),
        requested_dates: leaveDetails.map((detail) => ({
          request_day: detail.date,
          is_short_leave: detail.is_short_leave,
          leave_category: detail.leave_category,
        })),
      };

      if (
        !payload.employee_no ||
        !payload.reason ||
        payload.requested_dates.length === 0
      ) {
        setPopupMessage("Please ensure all fields are filled out correctly.");
        setPopupType("error");
        setShowPopup(true);
        return;
      }

      const response = await fetch(
        "https://back-dlh-hris.quortech-technologies.com/v1/hris/leave/addleave",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setPopupMessage(errorData.message || "Failed to submit leave request.");
        setPopupType("error");
        setShowPopup(true);
        return;
      }

      const result = await response.json();
      setPopupMessage("Leave request submitted successfully!");
      setPopupType("success");
      setShowPopup(true);
      onClose();
    } catch (error) {
      setPopupMessage(error.message || "An unexpected error occurred.");
      setPopupType("error");
      setShowPopup(true);
    }
  };

  return (
    <div className="bg-white p-5 rounded-lg w-[600px] h-[800px] relative flex flex-col items-center justify-center">
      <div className="mb-10 w-full">
        <div className="flex justify-end">
          <IoIosCloseCircle
            className="w-8 h-8 cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="flex justify-center mt-5">
          <h2 className="text-xl">Request Leave</h2>
        </div>
      </div>
      {/* Centered Calendar */}
      <div className="flex justify-center mb-5">
        <Calendar onClickDay={handleDateClick} />
      </div>

      {selectedDates.map((date, index) => (
        <div
          key={index}
          className="p-2 border rounded-lg mt-2 relative w-full max-w-xl"
        >
          <p className="font-semibold text-sm">Date: {date}</p>
          <button
            onClick={() => handleRemoveDate(index)}
            className="absolute top-2 right-2 text-red-500"
          >
            <FaTrashAlt />
          </button>
          <div className="mt-2">
            <label className="flex items-center">
              <div
                className={`w-8 h-5 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
                  leaveDetails[index].is_short_leave ? "bg-green-500" : ""
                }`}
                onClick={() =>
                  setLeaveDetails((prev) =>
                    prev.map((item, i) =>
                      i === index
                        ? { ...item, is_short_leave: !item.is_short_leave }
                        : item
                    )
                  )
                }
              >
                <div
                  className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                    leaveDetails[index].is_short_leave ? "translate-x-4" : ""
                  }`}
                ></div>
              </div>
              <span className="ml-3 text-sm">Short Leave</span>
            </label>
          </div>
          <select
            value={leaveDetails[index]?.leave_category || ""}
            onChange={(e) =>
              setLeaveDetails((prev) =>
                prev.map((item, i) =>
                  i === index
                    ? { ...item, leave_category: e.target.value }
                    : item
                )
              )
            }
            className="border mt-2 p-2 rounded w-full text-sm"
          >
            <option value="">Select Leave Type</option>
            {leaveTypes.map((type) => (
              <option key={type.category_id} value={type.category_id}>
                {type.category_name} ({type.leave_count})
              </option>
            ))}
          </select>
        </div>
      ))}

      <textarea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        className="w-full mt-5 p-2 border rounded"
        placeholder="Reason for leave"
      />
      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white mt-5 px-5 py-2 rounded"
      >
        Submit
      </button>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded shadow-md">
            <h2
              className={`text-xl font-bold mb-4 ${
                popupType === "success" ? "text-green-500" : "text-red-500"
              }`}
            >
              {popupType === "success" ? "Success" : "Error"}
            </h2>
            <p>{popupMessage}</p>
            <button
              onClick={() => setShowPopup(false)}
              className="mt-3 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaveRequestUser;
