import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { BiLogOutCircle } from "react-icons/bi";
import Logo from "../../../../assets/qtlogo.png";
import Reset_Pw from "../../employee/dashbaord/reset_password";
import Cookies from "js-cookie";

const Navbar = () => {
  // Fetch the user's calling name from cookies
  const user = Cookies.get("employee_calling_name");
  
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // Handle user logout
  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();

    // Remove cookies
    Cookies.remove("employee_calling_name");

    // Clear cache and redirect to login
    window.location.href = `/login?cache_buster=${new Date().getTime()}`;
  };

  return (
    <div>
      {/* Navbar container */}
      <div className="flex justify-center mt-[69px] font-sans">
        <div className="shadow-lg bg-white w-[1600px] h-[83px] rounded-[24px]">
          <div className="flex justify-between items-center mt-3 px-4">
            
            {/* Logo section */}
            <div className="flex gap-5 items-center">
              <img src={Logo} alt="logo" className="w-[200px]" />
            </div>

            {/* User section */}
            <div className="flex items-center gap-5">
              <FaUser className="w-[20px] h-[20px]" />
              <p>{user}</p>

              {/* Dropdown toggle */}
              <div
                className="ml-4"
                onClick={() => setShowDropdown((prev) => !prev)}
              >
                <IoIosArrowDown className="w-[24px] h-[21px] cursor-pointer" />
                {showDropdown && (
                  <div className="absolute bg-white rounded-[30px] shadow-lg mt-1">
                    <p
                      className="py-2 cursor-pointer px-4 hover:bg-[#001F3F] hover:text-white hover:rounded-t-2xl"
                      onClick={() => setShowPopup(true)}
                    >
                      Reset Password
                    </p>
                  </div>
                )}
              </div>

              {/* Logout button */}
              <BiLogOutCircle
                className="w-[24px] h-[21px] cursor-pointer"
                onClick={handleLogout}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Reset Password Popup */}
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <Reset_Pw onClose={() => setShowPopup(false)} />
        </div>
      )}
    </div>
  );
};

export default Navbar;
