import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Login_Right from "../../../assets/qtlogo.png";
import LoginImg from "../../../assets/login.jpg";

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [employeeNo, setEmployeeNo] = useState("");

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  };

  const getCookie = (name) => {
    const cookieArr = document.cookie.split("; ");
    for (const cookie of cookieArr) {
      const [key, value] = cookie.split("=");
      if (key === name) {
        return decodeURIComponent(value);
      }
    }
    return null;
  };

  useEffect(() => {
    const employeeNoCookie = getCookie("employee_no");
    if (employeeNoCookie) {
      setEmployeeNo(employeeNoCookie);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://back-dlh-hris.quortech-technologies.com/v1/hris/user/userLogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: email, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const { user_token, ...userDetails } = data;

        setCookie("token", user_token, 7);
        setCookie("user", JSON.stringify(userDetails), 7);
        setCookie("employee_no", userDetails.employee_no, 7);

        localStorage.setItem("token", user_token);

        navigate("/home");
      } else {
        const errorData = await response.json();
        setLoginError(errorData.message || "Invalid email or password");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setLoginError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="flex h-screen overflow-hidden font-montserrat">
      {/* Left Section */}
      <div className="w-1/2 flex flex-col justify-center items-center bg-white px-16 space-y-8">
        <div className="mb-8">
          <img src={Login_Right} alt="Logo" className="w-[250px]" />
        </div>
        <h2 className="text-5xl font-bold text-gray-800 text-center">Login</h2>

        <form onSubmit={handleSubmit} className="space-y-8 w-full max-w-lg">
          <div className="relative">
            <label
              htmlFor="email"
              className="absolute -top-3 left-4 bg-white px-2 text-sm text-gray-600"
            >
              Employee Number
            </label>
            <input
              id="email"
              placeholder="Enter your Employee ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-6 py-4 text-lg bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
            />
          </div>

          <div className="relative">
            <label
              htmlFor="password"
              className="absolute -top-3 left-4 bg-white px-2 text-sm text-gray-600"
            >
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-6 py-4 text-lg bg-gray-50 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-blue-600"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-4 flex items-center"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FiEyeOff className="text-gray-600 h-6 w-6" />
                ) : (
                  <FiEye className="text-gray-600 h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          {loginError && (
            <div className="text-red-500 text-center">{loginError}</div>
          )}

          <div className="flex items-center space-x-4">
            <input
              type="checkbox"
              id="remember"
              className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="remember" className="text-base text-gray-600">
              Remember me
            </label>
          </div>

          <button
            type="submit"
            className="w-full py-4 text-xl font-bold text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            Login
          </button>
        </form>
      </div>

      {/* Right Section */}
      <div className="w-1/2">
        <img
          src={LoginImg}
          alt="Login Illustration"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
}

export default Login;
