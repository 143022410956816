import React, { useState, useEffect } from "react";
import moment from "moment";

const AttendanceTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employee_no = getCookie("employee_no");
        const response = await fetch(
          `https://back-dlh-hris.quortech-technologies.com/v1/hris/leave/getLeaveByEmployeeNo?employee_no=${employee_no}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        const sortedData = result.sort((a, b) => {
          return new Date(a.requested_date) - new Date(b.requested_date);
        });

        setData(sortedData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg mx-auto w-[80%] mt-10">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-[#001F3F]">Leave Overview</h2>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="py-2 text-left text-sm font-semibold text-gray-600">
              Requested Date
            </th>
            <th className="py-2 text-left text-sm font-semibold text-gray-600">
              Leave Type
            </th>
            <th className="py-2 text-left text-sm font-semibold text-gray-600">
              Reason
            </th>
            <th className="py-2 text-left text-sm font-semibold text-gray-600">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {currentRows.map((item, index) => (
            <tr key={index}>
              <td className="py-2">
                {moment(item.requested_date).format("DD-MM-YYYY")}
              </td>
              <td className="py-2">
                <span className="text-gray-500">
                  {item.category_name || "No Leave Type"}
                </span>
              </td>
              <td className="py-2">
                <span className="text-gray-500">
                  {item.reason || "No Reason Provided"}
                </span>
              </td>
              <td className="py-2">
                <span
                  className={`py-1 px-3 rounded-full text-sm ${
                    item.approved_status_1 === "APPROVED"
                      ? "bg-[#E0F3E8] text-[#34D399]"
                      : item.approved_status_1 === "COMMUNICATE"
                      ? "bg-[#E0E8F9] text-[#5A9CFC]"
                      : "bg-[#FDE2E2] text-[#F87171]"
                  }`}
                >
                  {item.approved_status_1 || "Unknown"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className="text-center py-4 text-gray-500">
          No leave data available.
        </div>
      )}
      <div className="flex justify-center mt-4">
        <Pagination
          rowsPerPage={rowsPerPage}
          totalRows={data.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="flex pl-0 list-none rounded">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`cursor-pointer ${
              currentPage === number
                ? "bg-[#001F3F] text-white"
                : "bg-white text-[#001F3F]"
            } py-2 px-4 mx-1 rounded-full border border-[#001F3F]`}
            onClick={() => paginate(number)}
          >
            <a>{number}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AttendanceTable;
