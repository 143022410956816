import React, { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import Cookies from "js-cookie";

const ResetPassword = ({ onClose }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(""); // State for success message

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match");
      return;
    }

    try {
      const employee_no = Cookies.get("employee_no");

      if (!employee_no) {
        setError("Employee number not found in cookies");
        return;
      }

      const formData = {
        employee_no: employee_no,
        old_password: oldPassword,
        new_password: newPassword,
      };

      const response = await fetch(
        `https://back-dlh-hris.quortech-technologies.com/v1/hris/user/resetPassword`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        setSuccess("Password updated successfully"); // Set success message
        setError(""); // Clear any previous errors
        // Optionally, you can reset the form fields
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setError(
          "Failed to update password: " + (data.error || "Unknown error")
        );
        setSuccess(""); // Clear any previous success message
      }
    } catch (error) {
      console.error("There was an error!", error);
      setError("An error occurred while updating the password.");
      setSuccess(""); // Clear any previous success message
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-10 relative">
        <div className="flex justify-between items-center mb-5">
          <p className="text-[#001F3F] text-[40px] font-semibold">
            Reset Password
          </p>
          <IoMdCloseCircle
            className="w-8 h-8 cursor-pointer"
            onClick={onClose}
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}{" "}
        {/* Success message */}
        <form onSubmit={handleSubmit}>
          <div>
            <label className="text-[#001F3F] font-semibold text-[20px]">
              Old Password
            </label>
            <input
              type="password"
              className="w-full h-[35px] border border-black rounded-xl"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>

          <div className="mt-2">
            <label className="text-[#001F3F] font-semibold text-[20px]">
              New Password
            </label>
            <input
              type="password"
              className="w-full h-[35px] border border-black rounded-xl"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>

          <div className="mt-2">
            <label className="text-[#001F3F] font-semibold text-[20px]">
              Confirm Password
            </label>
            <input
              type="password"
              className="w-full h-[35px] border border-black rounded-xl"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>

          <div className="mt-5 flex justify-end">
            <button
              type="submit"
              className="bg-[#001F3F] p-3 text-white font-semibold rounded-lg"
            >
              Done
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
